<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.addAbsence.title')"
				max-width="496"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<v-form v-mutate="onIntersect" :ref="refs.form" v-model="formValid" :readonly="isFormSaving">
				<frp-form-item>
					<bod-autocomplete item-text="text"
									  item-value="value"
									  class="kpi-field"
									  :items="absenceTypes"
									  v-model="absenceType"
									  color="blue"
									  required
									  :label="$t('fields.absenceTypes.label')"
									  :placeholder="$t('fields.absenceTypes.placeholder')">
					</bod-autocomplete>
				</frp-form-item>
				
				<frp-form-item>
					<hr-range-date-field v-model="datesRange"
										 :max-days="365"
										 :min-date="minDate"
										 :max-date="maxDate"
										 :calendar-dates="calendarDates"
										 required
										 :label="$tc('fields.vacationPeriod.label')"
										 :placeholder="$tc('fields.vacationPeriod.placeholder')"
										 type="number">
					</hr-range-date-field>
				</frp-form-item>
				
				<frp-form-item class="mb-2">
					<bod-autocomplete v-model="employeeId"
									  :items="employees"
									  :loading="isEmployeesLoading"
									  @firstclick="fetchEmployees"
									  item-text="fullName"
									  item-value="id"
									  :max-height="200"
									  color="blue"
									  required
									  :label="$t('fields.hrEmployee.label')"
									  :placeholder="$t('fields.hrEmployee.placeholder')">
					</bod-autocomplete>
				</frp-form-item>
				
				<frp-form-item v-if="absenceType === ApiHrAbsenceTypeEnum.Other">
					<frp-textarea class="kpi-field"
								  v-model="comment"
								  required
								  color="blue"
								  :label="$t('fields.hrVacationDaysComment.label')"
								  :placeholder="$t('fields.hrVacationDaysComment.placeholder')">
					</frp-textarea>
				</frp-form-item>
			</v-form>
		</template>
		
		<template #footer>
			<frp-btn outlined color="primary"
					 @click="handleClose">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0" color="blue" dark
					 :loading="isFormSaving"
					 :disabled="!isFormValid"
					 @click="handleSave">
				{{ $t("buttons.add") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import { ApiHrAbsenceTypeEnum } from "@/api/hr/types/absence/ApiHrAbsenceTypeEnum";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTreeAutocomplete from "@/components/fields/FrpTreeAutocomplete.vue";
import HrRangeDateField from "@/components/fields/HrRangeDateField.vue";
import FrpFormItem from "@/components/markup/FrpFormItem.vue";
import formMixin from "@/mixins/formMixin";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import { endOfYear, setYear, startOfYear } from "date-fns";
import { RouteNames } from "Router/hr/routes";
import { actionTypes, getterTypes, mutationTypes, namespace } from "Store/hr/modules/absences/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, formMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace,
			ApiHrAbsenceTypeEnum,
			absenceTypes: Object.values(ApiHrAbsenceTypeEnum).filter(x => {
				return [ApiHrAbsenceTypeEnum.Study, ApiHrAbsenceTypeEnum.TraineeJob, ApiHrAbsenceTypeEnum.Other].includes(x);
			}).map(x => ({ text: this.$t(`aliases.absenceType.${x}`), value: x })),
		};
	},
	computed: {
		...mapState({
			isEmployeesLoading: state => state.isEmployeesLoading,
			employees: state => state.employees,
			newAbsence: state => state.newAbsence,
			calendarDates: state => state.calendarDates,
			years: state => state.years,
			year: state => state.year,
		}),
		absenceType: {
			get() {
				return this.newAbsence.type;
			},
			set(value) {
				this.setNewAbsenceType(value);
			}
		},
		datesRange: {
			get() {
				const { startDate, endDate } = this.newAbsence;
				
				return [startDate, endDate];
			},
			set([startDate, endDate]) {
				this.setNewAbsenceStartDate(startDate);
				this.setNewAbsenceEndDate(endDate);
			}
		},
		employeeId: {
			get() {
				return this.newAbsence.employeeId;
			},
			set(value) {
				this.setNewAbsenceEmployeeId(value);
			}
		},
		comment: {
			get() {
				return this.newAbsence.comment;
			},
			set(value) {
				this.setNewAbsenceComment(value);
			}
		},
		minDate() {
			if(!this.years.length) return;
			
			const year = this.years.some(x => x.value === this.year - 1) ? this.year - 1 : this.year;
			
			return startOfYear(setYear(new Date(), year));
		},
		maxDate() {
			if(!this.years.length) return;
			
			const year = this.years.some(x => x.value === this.year + 1) ? this.year + 1 : this.year;
			
			return endOfYear(setYear(new Date(), year));
		}
	},
	methods: {
		...mapMutations({
			resetNewAbsence: mutationTypes.RESET_NEW_ABSENCE,
			setNewAbsenceType: mutationTypes.SET_NEW_ABSENCE_TYPE,
			setNewAbsenceStartDate: mutationTypes.SET_NEW_ABSENCE_START_DATE,
			setNewAbsenceEndDate: mutationTypes.SET_NEW_ABSENCE_END_DATE,
			setNewAbsenceEmployeeId: mutationTypes.SET_NEW_ABSENCE_EMPLOYEE_ID,
			setNewAbsenceComment: mutationTypes.SET_NEW_ABSENCE_COMMENT,
		}),
		...mapActions({
			tryCreateAbsence: actionTypes.tryCreateAbsence,
			fetchEmployees: actionTypes.fetchEmployees
		}),
		async handleSave() {
			const res = await this.tryCreateAbsence();
			
			if(res)
				this.$emit("update:value", false);
		},
		handleClose() {
			this.$emit("update:value", false);
		}
	},
	watch: {
		value: {
			async handler() {
				this.resetNewAbsence();
				
				await this.$nextTick();
				
				this.$refs.form.resetValidation();
			},
			immediate: true
		}
	},
	components: {
		FrpTextarea,
		HrRangeDateField, FrpFormItem,
		FrpTreeAutocomplete,
		FrpDialog,
		BodAutocomplete,
		FrpBtn
	}
};
</script>
