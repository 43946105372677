<template>
	<hr-card :title="$t('titles.standardAwareness')" style="min-width: min-content"
			 :centered-content="!isInitialized || isNil(standardAwareness?.correctAnswersRate)">
		<div class="d-flex justify-center align-center flex-column fill-height" style="width: 100%">
			<div style="width: 100%" class="fill-height d-flex align-center justify-center text-center">
				В четвертом квартале тестирование не проводилось
			</div>
<!--			<v-progress-circular v-if="!isInitialized" indeterminate color="primary"></v-progress-circular>-->
<!--			<div style="width: 100%" class="fill-height d-flex align-center justify-center mb-n4"-->
<!--				 v-else-if="isNil(standardAwareness?.correctAnswersRate)">-->
<!--				{{ $t("alerts.info.noData") }}-->
<!--			</div>-->
<!--			<div v-else class="d-flex align-center justify-space-between" style="width: 100%">-->
<!--				<hr-chart-legend>-->
<!--					<hr-chart-legend-item :color="colors.blue.lighten5">{{ chartLabel }}</hr-chart-legend-item>-->
<!--					<hr-chart-legend-item :color="rewardColor" :text-color="rewardColor">-->
<!--						{{ `${rewardValue} ${$t("chart.legend.rewardRate")}` }}-->
<!--					</hr-chart-legend-item>-->
<!--				</hr-chart-legend>-->
<!--				<hr-chart-pie :label="chartLabel" :value="standardAwareness.correctAnswersRate"></hr-chart-pie>-->
<!--			</div>-->
			<div class="align-self-start">
				<frp-link-btn :href="externalUrls.learning" :underline="false" target="_blank">
					<frp-btn color="primary"
							 dense
							 outlined
							 elevation="0">
						{{ $t("buttons.passTest") }}
					</frp-btn>
				</frp-link-btn>
			</div>
		</div>
	</hr-card>
</template>

<script>
import { ApiKpiQuarterEnum } from "Api/kpi/types/ApiKpiQuarterEnum";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpLinkBtn from "Components/buttons/FrpLinkBtn";
import HrCard from "Components/cards/HrCard";
import HrChartLegendItem from "Components/charts/HrChartLegendItem";
import HrChartLegend from "Components/charts/HrChartLegend";
import HrChartPie from "Components/charts/HrChartPie";
import FrpIcon from "Components/icon/FrpIcon";
import { externalUrls } from "Constants/hr/externalUrls";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { actionTypes } from "Store/hr/modules/employee/modules/standardAwareness/types";
import storeManager from "Store/manager";
import { createNamespacedHelpers } from "vuex";
import { isNil } from "lodash";
import { subQuarters, getQuarter } from "date-fns";

const namespace = storeManager.hr.employee.standardAwareness.namespace;
const { mapState, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage],
	data() {
		return {
			namespace,
			isNil,
			externalUrls
		}
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			standardAwareness: state => state.standardAwareness
		}),
		subtitle() {
			return this.$t("subheaders.forQuarter", { quarter: ApiKpiQuarterEnum[getQuarter(subQuarters(new Date(), 1))] });
		},
		rewardColor() {
			if(this.standardAwareness.rewardRate === 0)
				return;
			if(this.standardAwareness.rewardRate > 0)
				return this.colors.green.lighten4;
			if(this.standardAwareness.rewardRate < 0)
				return this.colors.secondary.lighten2;
		},
		rewardValue() {
			const value = this.standardAwareness.rewardRate;
			return value > 0 ? `+${value}` : value;
		},
		chartLabel() {
			return this.$t("chart.legend.correctAnswersRate");
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		})
	},
	created() {
		// this.initializeStore();
	},
	components: {
		FrpLinkBtn,
		FrpBtn,
		HrChartPie,
		HrCard,
		HrChartLegend,
		HrChartLegendItem,
		FrpIcon
	}
};
</script>
