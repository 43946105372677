<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.vacationPlansApprove.title', { year: filter.year })"
				max-width="579"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #prepend v-if="employee">
			<v-row class="align-center flex-wrap pt-6 pb-2 pr-3 pl-2 primary--text text--darken-1">
				<v-col class="d-flex px-0" style="align-self: baseline">
					<frp-avatar :id="employee.pictureFileId" size="85" class="mr-5"></frp-avatar>
					
					<div class="d-flex flex-column justify-start">
						<span style="font-size: 20px; font-weight: 600" class="mb-1">
							{{ formatFullName(employee) }}
						</span>
						<span style="font-size: 16px; line-height: 22px">
							{{ employee.department.name }}
						</span>
						<span style="font-size: 16px; line-height: 22px">
							{{ employee.position }}
						</span>
					</div>
				</v-col>
			</v-row>
		</template>

		<template #subtitle="{ onIntersect }" v-if="plan">
			<hr-vacation-plan-state-label class="font-weight-bold" :value="plan.state"></hr-vacation-plan-state-label>
		</template>

		<template #content="{ onIntersect }" v-if="plan">
			<frp-text-field class="kpi-field mb-2"
							v-for="({ startDate, endDate, days }, i) in vacations"
							:key="i"
							hide-details
							:value="`${format(startDate, dateFormat)} - ${format(endDate, dateFormat)} (${days})`"
							readonly
							:label="$t('fields.period.label')">
			</frp-text-field>
		</template>

		<template #footer>
			<frp-btn outlined
					 @click="handleClose"
					 color="primary">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<template>
				<frp-btn elevation="0"
						 color="red"
						 dark
						 v-if="canDecline"
						 :disabled="plan?.state !== HrVacationPlanStateEnum.IN_REVIEW || !isFirstPlan"
						 @click="handleDecline">
					{{ $t("buttons.vacationPlansDecline") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 v-if="canApprove"
						 :disabled="plan?.state !== HrVacationPlanStateEnum.IN_REVIEW || !isFirstPlan"
						 :loading="isApproving"
						 @click="handleApprove">
					{{ $t("buttons.vacationPlansApprove") }}
				</frp-btn>
			</template>
		</template>
	</frp-dialog>
</template>

<script>
import FrpRouterLinkBtn from "@/components/buttons/FrpRouterLinkBtn.vue";
import FrpAvatar from "@/components/common/FrpAvatar.vue";
import HrVacationPlanStateLabel from "@/components/common/HrVacationPlanStateLabel.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import { HrVacationPlanStateEnum } from "@/types/hr/vacation/HrVacationPlanStateEnum";
import { dateFormat } from "@/utils/formats";
import { Permissions } from "@/constants/permissions";
import { formatFullName } from "@/utils/formatting";
import { format } from "date-fns";
import { isArray, last } from "lodash";
import { RouteNames } from "Router/hr/routes";
import { namespace } from "Store/hr/modules/vacationPlans";
import { actionTypes, getterTypes, mutationTypes } from "Store/hr/modules/vacationPlans/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpTextField from "Components/fields/FrpTextField";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace,
			Permissions,
			format,
			dateFormat,
			HrVacationPlanStateEnum,
			isFormValid: false,
			formatFullName
		};
	},
	computed: {
		...mapState({
			filter: state => state.filter,
			isApproving: state => state.isApproving,
			acceptPlanRequest: state => state.acceptPlanRequest,
			ganttEmployees: state => state.ganttEmployees,
		}),
		...mapGetters({
			filteredFormattedItems: getterTypes.filteredFormattedItems,
			currentUserPermissions: getterTypes.currentUserPermissions,
			currentUser: getterTypes.currentUser
		}),
		employee() {
			return this.filteredFormattedItems.map(x => x.employee).find(x => x.id === this.acceptPlanRequest.employeeId);
		},
		plan() {
			return this.filteredFormattedItems.map(x => x.vacationsPlans).flat().find(x => x.id === this.acceptPlanRequest.planId);
		},
		vacations() {
			return this.plan.plannedVacations.sort((a, b) => a.startDate - b.startDate);
		},
		isFirstPlan() {
			const employeePlans = this.filteredFormattedItems.find(x => this.acceptPlanRequest.employeeId === x.employee.id)?.vacationsPlans;
			return employeePlans?.length === 1;
		},
		canApprove() {
			const canApprovePlan = this.can(Permissions.HR_VACATIONS_PLAN_APPROVE) && this.ganttEmployees.some(x => x.id === this.employee?.id);
			const canApproveOwnPlan = this.can(Permissions.OWN_HR_VACATIONS_PLAN_APPROVE);
			
			return this.employee?.id === this.currentUser.id ? canApproveOwnPlan : canApprovePlan;
		},
		canDecline() {
			const canDeclinePlan = this.can(Permissions.HR_VACATIONS_PLAN_DECLINE) && this.ganttEmployees.some(x => x.id === this.employee?.id);
			const canDeclineOwnPlan = this.can(Permissions.OWN_HR_VACATIONS_PLAN_DECLINE);
			
			return this.employee?.id === this.currentUser.id ? canDeclineOwnPlan : canDeclinePlan;
		}
	},
	methods: {
		...mapMutations({
			resetAcceptPlanRequest: mutationTypes.RESET_ACCEPT_PLAN_REQUEST
		}),
		...mapActions({
			approvePlan: actionTypes.approvePlan
		}),
		async handleApprove() {
			await this.approvePlan();
			this.resetAcceptPlanRequest();

			this.$emit("update:value", false);
		},
		handleDecline() {
			this.$emit("decline", false);
			this.$emit("update:value", false);
		},
		handleClose() {
			this.$emit("update:value", false);
		},
		handleCancel() {
			this.resetAcceptPlanRequest();

			this.handleClose();
		},
		can(value) {
			if (isArray(value))
				return value.some(x => this.currentUserPermissions.includes(x));
			else
				return this.currentUserPermissions.includes(value);
		}
	},
	components: {
		FrpAvatar,
		HrVacationPlanStateLabel,
		FrpRouterLinkBtn,
		FrpDateField,
		FrpTextarea,
		FrpDialog,
		FrpBtn,
		FrpTextField
	}
};
</script>
