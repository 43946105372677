<template>
	<hr-content-layout v-if="isInitialized">
		<hr-profile></hr-profile>
		
		<v-row>
			<v-col v-bind="chartCols" v-if="permissions.includes(Permissions.HR_KPI_READ)" class="pl-2 pr-6"
				   style="flex-grow: 2; min-width: min-content; min-height: 276px !important;">
				<hr-kpi></hr-kpi>
			</v-col>
			<v-col v-bind="chartCols" v-if="permissions.includes(Permissions.HR_STANDARD_READ)" class="px-6"
				   style="flex-grow: 2; min-width: min-content; min-height: 276px !important;">
				<hr-standard-awareness></hr-standard-awareness>
			</v-col>
<!--			<v-col v-bind="chartCols" v-if="permissions.includes(Permissions.HR_FEEDBACK_READ)" class="px-2"-->
<!--				   style="flex-grow: 3; min-width: min-content; min-height: 244px !important;">-->
<!--				<hr-feedback></hr-feedback>-->
<!--			</v-col>-->
			<v-col v-bind="chartCols" v-if="permissions.includes(Permissions.HR_DISCIPLINE_READ)" class="pr-2 pl-6"
				   style="flex-grow: 2; min-width: min-content; min-height: 276px !important;">
				<hr-discipline></hr-discipline>
			</v-col>
		</v-row>
		
		<hr-colleagues v-if="permissions.includes(Permissions.HR_COLLEAGUE_READ)"></hr-colleagues>
		
		<hr-subordinates v-if="permissions.includes(Permissions.HR_SUBORDINATE_READ)"></hr-subordinates>
		
		<hr-require-vacation-application-dialog v-model="isRequireVacationApplicationDialogOpened"></hr-require-vacation-application-dialog>
	</hr-content-layout>
	<v-progress-circular v-else class="align-self-center mx-auto" size="44" indeterminate color="primary"></v-progress-circular>
</template>

<script>
import { namespace as userNamespace } from "@/store/hr/modules/user";
import HrRequireVacationApplicationDialog from "@/views/hr/employee/dialogs/HrRequireVacationApplicationDialog.vue";
import FrpInformationMenu from "Components/common/FrpInformationMenu";
import FrpPagination from "Components/common/FrpPagination";
import FrpConfirmDialog from "Components/dialogs/FrpConfirmDialog";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import FrpCheckboxGroup from "Components/fields/FrpCheckboxGroup";
import FrpRadioGroup from "Components/fields/FrpRadioGroup";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpIcon from "Components/icon/FrpIcon";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import HrContentLayout from "Components/layouts/HrContentLayout";
import { Permissions } from "@/constants/permissions";
import { RouteNames } from "Router/hr/routes";
import { namespace } from "Store/hr/modules/employee";
import HrColleagues from "Views/hr/employee/sections/HrColleagues";
import HrDiscipline from "Views/hr/employee/sections/HrDiscipline";
import HrExpectations from "Views/hr/employee/sections/HrExpectations";
import HrFeedback from "Views/hr/employee/sections/HrFeedback";
import HrKpi from "Views/hr/employee/sections/HrKpi";
import HrProfile from "Views/hr/employee/sections/HrProfile";
import HrPromises from "Views/hr/employee/sections/HrPromises";
import HrStandardAwareness from "Views/hr/employee/sections/HrStandardAwareness";
import HrSubordinates from "Views/hr/employee/sections/HrSubordinates";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import FrpAlert from "Components/alerts/FrpAlert";
import FrpAlerts from "Components/alerts/FrpAlerts";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpCheckbox from "Components/fields/FrpCheckbox";
import FrpTextField from "Components/fields/FrpTextField";
import BodMain from "Components/layouts/BodMain";
import { uniqBy, isNil } from "lodash";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);
const { mapState: mapUserState } = createNamespacedHelpers(userNamespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage],
	metaInfo() {
		return {
			title: this.title
		};
	},
	data() {
		return {
			RouteNames,
			Permissions,
			namespace,
			isRequireVacationApplicationDialogOpened: false,
			uniqBy,
			isNil
		};
	},
	computed: {
		...mapUserState({
			isUserInitialized: state => state.isInitialized
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			closestVacation: state => state.closestVacation,
			isDepartmentsLoading: state => state.isDepartmentsLoading,
			departments: state => state.departments,
			permissions: state => state.permissions
		}),
		chartsCount() {
			return this.permissions.filter(x => [Permissions.HR_KPI_READ, Permissions.HR_DISCIPLINE_READ, Permissions.HR_STANDARD_READ,
				Permissions.HR_FEEDBACK_READ].includes(x)).length;
		},
		chartCols() {
			switch (this.chartsCount) {
				case 4:
					return {}
				case 3:
					return { cols: 12, md: 6, lg: 3, xl: 4 };
				case 2:
					return { cols: 12, md: 6, lg: 4 };
				case 1:
					return { cols: 12, md: 6, xl: 4 };
			}
		}
	},
	methods: {
		...mapMutations({}),
		...mapActions({}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] =
				Array.isArray(this.filterValues[key]) ? [...this.filterValues[key]] : this.filterValues[key]);
		}
	},
	created() {
		if(this.isUserInitialized)
			this.initializeStore();
	},
	async beforeRouteUpdate(to, from, next) {
		if(this.isStateInitializedInternal)
			await this.destroyStore();
		await next();
		await this.initializeStore();
	},
	watch: {
		isUserInitialized(value) {
			if(value)
				this.initializeStore();
		},
		closestVacation(value) {
			if(value.id)
				this.isRequireVacationApplicationDialogOpened = true;
		}
	},
	components: {
		HrRequireVacationApplicationDialog,
		HrPromises,
		HrExpectations,
		HrContentLayout,
		FrpInformationMenu,
		FrpConfirmDialog,
		FrpTextarea,
		FrpRadioGroup,
		FrpCheckboxGroup,
		FrpBottomSpace,
		BodAutocomplete,
		FrpPagination,
		FrpIcon,
		FrpBtn,
		FrpCheckbox,
		FrpTextField,
		FrpAutocomplete,
		BodMain,
		FrpAlerts,
		FrpAlert,
		HrProfile,
		HrDiscipline,
		HrFeedback,
		HrKpi,
		HrStandardAwareness,
		HrSubordinates,
		HrColleagues
	}
};
</script>
