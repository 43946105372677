<template>
	<div>
		<v-timeline class="hr-approval">
			<v-timeline-item v-if="employee && (!noCreatedAt && !isPlannedVacation)" :color="colors.green.lighten3" right>
				<div class="d-flex flex-column">
					<span>{{ formatFullName(employee) }}</span>
					<span :style="{ color: colors.green.lighten3 }">
						{{ $t("content.vacationApplicationApproverStateText.created") }} {{ formatDate(createdAt, dateFormat) }}
					</span>
				</div>
			</v-timeline-item>
			
			<v-timeline-item v-for="approver in (approvers || []).filter(x => x.state === ApiHrVacationApplicationStateEnum.Approved)"
							 :key="approver.id" :color="getColor(approver.state)" right>
				<div class="d-flex flex-column">
					<div class="d-flex align-center">
						<span>{{ formatFullName(approver.employee) }}</span>
						<frp-btn v-if="deletableApprovers && canDeleteApprover(approver)"
								 @click="$emit('delete', approver.employee.id)"
								 :disabled="isDelitingDisabled"
								 class="ml-2" icon height="24" without-padding :color="colors.primary.base" style="width: 24px;">
							<frp-icon width="22" height="22" src="ico_kpi-delete"></frp-icon>
						</frp-btn>
					</div>
					<span :style="{ color: getColor(approver.state) }">
						{{ $t("content.vacationApplicationApproverStateText.approved") }} {{ formatDate(approver.stateUpdatedAt, dateFormat) }}
					</span>
				</div>
			</v-timeline-item>
			
			<v-timeline-item :color="colors.blue.base" right v-if="state === ApiHrVacationApplicationStateEnum.Withdrawn">
				<div class="d-flex flex-column">
					<span>{{ formatFullName(stateUpdatedBy) }}</span>
					<span :style="{ color: colors.blue.base }">
					{{ $t("content.vacationApplicationApproverStateText.withdrawn") }} {{ formatDate(stateUpdatedAt, dateFormat) }}
				</span>
				</div>
			</v-timeline-item>
			
			<v-timeline-item v-for="approver in notApprovedApprovers"
							 :key="approver.id" :color="getColor(approver.state)" right>
				<div class="d-flex flex-column">
					<div class="d-flex align-center">
						<span>{{ formatFullName(approver.employee) }}</span>
						<frp-btn v-if="deletableApprovers && canDeleteApprover(approver)"
								 @click="$emit('delete', approver.employee.id)"
								 :disabled="isDelitingDisabled"
								 class="ml-2" icon height="24" without-padding :color="colors.primary.base" style="width: 24px;">
							<frp-icon width="22" height="22" src="ico_kpi-delete"></frp-icon>
						</frp-btn>
					</div>
					<span v-if="approver.state === ApiHrVacationApplicationStateEnum.InReview" :style="{ color: getColor(approver.state) }">
						{{ $t("content.vacationApplicationApproverStateText.inReview") }} {{ formatDate(approver.stateUpdatedAt, dateFormat) }}
					</span>
					<span v-if="approver.state === ApiHrVacationApplicationStateEnum.Declined" :style="{ color: getColor(approver.state) }">
						{{ $t("content.vacationApplicationApproverStateText.declined") }} {{ formatDate(approver.stateUpdatedAt, dateFormat) }}
					</span>
					<span v-if="approver.state === ApiHrVacationApplicationStateEnum.Withdrawn" :style="{ color: getColor(approver.state) }">
						{{ $t("content.vacationApplicationApproverStateText.taskWithdrawn") }} {{ formatDate(approver.stateUpdatedAt, dateFormat) }}
					</span>
				</div>
			</v-timeline-item>
			
			<v-timeline-item v-if="isPlannedVacation" :color="colors.green.lighten3" right>
				<div class="d-flex flex-column">
					<span>{{ $t("content.hrPortal") }}</span>
					<span :style="{ color: colors.green.lighten3 }">
						{{ $t("content.vacationApplicationApproverStateText.createdAndApproved") }} {{ formatDate(createdAt, dateFormat) }}
					</span>
				</div>
			</v-timeline-item>
		</v-timeline>
	</div>
</template>

<script>
import { ApiHrVacationApplicationStateEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationApplicationStateEnum";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import colorsMixin from "@/mixins/colorsMixin";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { formatFullName } from "@/utils/formatting";

export default {
	components: { FrpBtn, FrpIcon },
	mixins: [colorsMixin],
	props: {
		state: {
			type: String,
			required: true
		},
		approvers: Array,
		employee: Object,
		stateUpdatedBy: Object,
		createdAt: Number,
		stateUpdatedAt: Number,
		isDelitingDisabled: Boolean,
		deletableApprovers: Boolean,
		noCreatedAt: Boolean,
		isPlannedVacation: Boolean
	},
	data() {
		return {
			dateFormat,
			ApiHrVacationApplicationStateEnum
		};
	},
	computed: {
		notApprovedApprovers() {
			const isDeclined = this.state === ApiHrVacationApplicationStateEnum.Declined;
			return (this.approvers || []).filter(x => isDeclined ? x.state === this.state : x.state !== ApiHrVacationApplicationStateEnum.Approved);
		}
	},
	methods: {
		formatDate,
		formatFullName,
		getColor(state) {
			switch (state) {
				case ApiHrVacationApplicationStateEnum.Approved:
					return this.colors.green.lighten3;
				case ApiHrVacationApplicationStateEnum.InReview:
					return this.colors.orange.darken4;
				case ApiHrVacationApplicationStateEnum.Declined:
					return this.colors.secondary.lighten2;
				case ApiHrVacationApplicationStateEnum.Withdrawn:
					return this.colors.blue.base;
				case ApiHrVacationApplicationStateEnum.New:
				default:
					return this.colors.grey.darken2;
			}
		},
		canDeleteApprover(approver) {
			const isInReview = this.state === ApiHrVacationApplicationStateEnum.InReview;
			const isApproverDeletable = ![ApiHrVacationApplicationStateEnum.Approved, ApiHrVacationApplicationStateEnum.Declined].includes(
				approver.state);
			
			return isInReview && isApproverDeletable && this.approvers.length !== 1;
		}
	}
};
</script>

<style lang="scss">
.hr-approval {
	padding-top: 0;

	&::before {
		left: 22px !important;
		top: 35px;
		height: calc(100% - 70px);
	}

	.v-timeline-item {
		padding-bottom: 0;
		min-height: 72px;
		align-items: center;
		justify-content: flex-end;

		&:last-child {
			padding-bottom: 0;
		}
	}

	.v-timeline-item__body {
		margin-left: 18px;
		max-width: initial !important;
	}

	.v-timeline-item__divider {
		min-width: 46px;
	}

	.v-timeline-item__dot {
		width: 32px;
		height: 32px;
		box-shadow: none;

		.v-timeline-item__inner-dot {
			width: 32px;
			height: 32px;
			margin: 0;
		}
	}
}
</style>
