<template>
	<hr-card :title="$t('titles.employeeKpi')" :subtitle="subtitle" style="min-width: min-content"
			 :centered-content="!isInitialized || isNil(kpi?.bonus)"
			 no-margin-top
			 :href="kpiUrl">
		<v-progress-circular v-if="!isInitialized" indeterminate color="primary"></v-progress-circular>
		<div style="width: 100%" class="fill-height d-flex align-center justify-center mb-4" v-else-if="isNil(kpi?.bonus)">
			{{ $t("alerts.info.noData") }}
		</div>
		<template v-else>
			<hr-chart-legend>
				<hr-chart-legend-item :color="colors.blue.lighten5">{{ chartLabel }}</hr-chart-legend-item>
				<hr-chart-legend-item v-if="!isNil(kpi.laborRate)">
					{{ `${$t("chart.legend.laborRate")} - ${kpi.laborRate} %` }}
				</hr-chart-legend-item>
			</hr-chart-legend>
			<hr-chart-pie :label="chartLabel" :value="kpi.bonus" class="mr-xl-11 mr-lg-2" size="180"></hr-chart-pie>
		</template>
	</hr-card>
</template>

<script>
import { ApiKpiQuarterEnum } from "Api/kpi/types/ApiKpiQuarterEnum";
import HrCard from "Components/cards/HrCard";
import HrChartLegendItem from "Components/charts/HrChartLegendItem";
import HrChartLegend from "Components/charts/HrChartLegend";
import HrChartPie from "Components/charts/HrChartPie";
import FrpIcon from "Components/icon/FrpIcon";
import { externalUrls } from "Constants/hr/externalUrls";
import { getQuarter, getYear } from "date-fns";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { actionTypes } from "Store/hr/modules/employee/modules/kpi/types";
import storeManager from "Store/manager";
import { createNamespacedHelpers } from "vuex";
import { isNil } from "lodash";

const namespace = storeManager.hr.employee.kpi.namespace;
const { mapState, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, storeModuleBasedPage],
	data() {
		return {
			namespace,
			isNil,
			externalUrls
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			kpi: state => state.kpi,
			period: state => state.period,
		}),
		subtitle() {
			return this.$t("subheaders.forPeriod", { quarter: ApiKpiQuarterEnum[this.period.quarter], year: this.period.year });
		},
		chartLabel() {
			return this.$t("chart.legend.completionRate");
		},
		kpiUrl() {
			return externalUrls.kpi ?
				`${externalUrls.kpi}/${getYear(new Date())}/${getQuarter(new Date())}?staffIds=${this.$route.params.id}` :
				undefined;
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		})
	},
	created() {
		this.initializeStore();
	},
	components: {
		HrChartPie,
		HrCard,
		HrChartLegend,
		HrChartLegendItem,
		FrpIcon
	}
};
</script>
