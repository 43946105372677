
<template>
	<kpi-content-layout v-if="initialized">
		<v-row>
			<v-col cols="12">
				<v-card class="kpi-card pa-3" style="height: calc(100vh - 164px); min-height: 500px">
					<hr-organization-structure-diagram id="structure"
													   :items="items"
													   @update:employees-opened="handleEmployeesMenu"
													   @update:employee-opened="handleEmployeeMenu"
													   @update:substructure-opened="handleSubstructureDialog"
													   @update:scale="scale = $event"
													   :opened-employee-id="openedEmployeeMenuId"
													   :opened-employees-department-id="openedEmployeesMenuDepartmentId"
													   :employees-opened="isEmployeesMenuOpened"
													   :employee-opened="isEmployeeMenuOpened"
													   :substructure-opened="isSubstructureDialogOpened">
					</hr-organization-structure-diagram>
				</v-card>
				
				<v-menu v-model="isEmployeesMenuOpened"
						v-if="isEmployeesMenuOpened"
						:position-x="employeesMenuPositionX"
						:position-y="employeesMenuPositionY"
						:close-on-click="false"
						:close-on-content-click="false"
						z-index="300"
						max-height="300"
						max-width="250"
						min-width="250"
						absolute
						:content-props="{ style: `transform: scale(${scale + 0.4})` }"
						content-class="white frp-scrollbar employees-menu">
					<v-list v-if="openedDepartmentEmployees?.length">
						<v-list-item v-for="(employee, i) in openedDepartmentEmployees" :key="employee.id">
							<div class="d-flex flex-column" style="width: 100%">
								<div class="d-flex align-center overflow-hidden" style="line-height: 12px">
									<frp-avatar :src="employee.photo" size="23" class="mr-3"></frp-avatar>
									
									<div class="d-flex flex-column" style="max-width: 183px">
										<frp-router-link-btn :to="{ name: RouteNames.EMPLOYEE, params: { id: employee.id } }" target="_blank">
											<span style="font-size: 11px" class="blue--text frp-text-overflow-ellipsis org-item-link"
												  :title="employee.name">
												{{ employee.name }}
											</span>
										</frp-router-link-btn>
										<span style="font-size: 10px" class="grey--text frp-text-overflow-ellipsis" :title="employee.position">
											{{ employee.position }}
										</span>
									</div>
								</div>
								
								<v-divider v-if="!i" class="mt-2"></v-divider>
							</div>
						</v-list-item>
					</v-list>
				</v-menu>
				
				<v-menu v-model="isEmployeeMenuOpened"
						v-if="isEmployeeMenuOpened"
						:position-x="employeeMenuPositionX"
						:position-y="employeeMenuPositionY"
						:close-on-click="false"
						:close-on-content-click="false"
						absolute
						z-index="310"
						min-width="365"
						max-width="365"
						:content-props="{ style: `transform: scale(${scale + 0.4})` }"
						content-class="white employee-menu">
					<div class="pa-5" v-if="openedEmployee" :data-user-id="openedEmployee.id">
						<div class="d-flex">
							<frp-avatar :src="openedEmployee.photo" size="57" :id="openedEmployee.pictureFileId" class="mr-4"></frp-avatar>
							
							<div class="d-flex flex-column">
								<frp-router-link-btn :to="{ name: RouteNames.EMPLOYEE, params: { id: openedEmployee.id } }" target="_blank">
									<span style="font-size: 18px" class="blue--text org-item-link">{{ openedEmployee.name }}</span>
								</frp-router-link-btn>
								
								<div class="mt-5" style="line-height: 14px">
									<div v-if="openedEmployee.department">
										<span style="font-size: 11px" class="grey--text mr-1">Подразделения:</span>
										<span style="font-size: 12px">{{ openedEmployee.department }}</span>
									</div>
									<div class="mt-2" v-if="openedEmployee.headName">
										<span style="font-size: 11px" class="grey--text mr-1">Кому подчиняется:</span>
										<span style="font-size: 12px">{{ openedEmployee.headName }}</span>
									</div>
									<div class="mt-2" v-if="openedEmployee.email">
										<span style="font-size: 11px" class="grey--text mr-1">E-mail:</span>
										<span style="font-size: 12px">{{ openedEmployee.email }}</span>
									</div>
									<div class="mt-2" v-if="openedEmployee.city">
										<span style="font-size: 11px" class="grey--text mr-1">Город:</span>
										<span style="font-size: 12px">{{ openedEmployee.city }}</span>
									</div>
									<div class="mt-2" v-if="openedEmployee.phone">
										<span style="font-size: 11px" class="grey--text mr-1">Внутренний телефон:</span>
										<span style="font-size: 12px">{{ openedEmployee.phone }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</v-menu>
			</v-col>
		</v-row>
		
		<hr-subsrtucture-dialog v-model="isSubstructureDialogOpened">
			<template #content="{ onIntersect }">
				<div class="kpi-card pa-3 mx-2"
					 style="max-width: 100%; max-height: calc(100vh - 150px)"
					 :style="`height: ${(substructureLevelsCount * 227) * scale + 24}px;
					  		  width: ${(substructureLevelMaxItemsCount * 300) * scale + 24}px`">
					<hr-organization-structure-diagram id="substructure"
													   ref="substructure"
													   v-if="isSubstructureDialogOpened"
													   no-substructure
													   :scale="scale"
													   :items="dialogItems"
													   lines-color="white"
													   @update:employees-opened="handleEmployeesMenu"
													   @update:employee-opened="handleEmployeeMenu"
													   :opened-employee-id="openedEmployeeMenuId"
													   :opened-employees-department-id="openedEmployeesMenuDepartmentId"
													   :employees-opened="isEmployeesMenuOpened"
													   :employee-opened="isEmployeeMenuOpened"
													   :substructure-opened="isSubstructureDialogOpened">
					</hr-organization-structure-diagram>
				</div>
			</template>
		</hr-subsrtucture-dialog>
	</kpi-content-layout>
	<div v-else class="fill-height flex-grow-1 d-flex align-center justify-center">
		<frp-spinner-loader width="5" color="primary"></frp-spinner-loader>
	</div>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpRouterLinkBtn from "@/components/buttons/FrpRouterLinkBtn.vue";
import FrpAvatar from "@/components/common/FrpAvatar.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import HrSubsrtuctureDialog from "@/components/dialogs/HrSubsrtuctureDialog.vue";
import KpiContentLayout from "@/components/layouts/KpiContentLayout.vue";
import FrpSpinnerLoader from "@/components/loaders/common/FrpSpinnerLoader.vue";
import HrOrganizationStructureDiagram from "@/components/common/HrOrganizationStructureDiagram.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/hr/routes";
import { flatMapDeep, groupBy, max, uniq } from "lodash";
import { namespace, mutationTypes } from "Store/hr/modules/organizationStructure/types";
import { createNamespacedHelpers } from "vuex";
import("basicprimitives/css/primitives.css");

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, authorizationMixin],
	metaInfo() {
		return {
			title: this.$t("titles.organizationStructure")
		};
	},
	data() {
		return {
			namespace,
			openedEmployeesMenuDepartmentId: "",
			openedEmployeeMenuId: "",
			isEmployeesMenuOpened: false,
			isEmployeeMenuOpened: false,
			isSubstructureDialogOpened: false,
			substructureLevelsCount: 0,
			substructureLevelMaxItemsCount: 0,
			employeesMenuPositionX: 0,
			employeesMenuPositionY: 0,
			employeeMenuPositionX: 0,
			employeeMenuPositionY: 0,
			scale: 0.6,
		};
	},
	computed: {
		RouteNames() {
			return RouteNames
		},
		...mapState({
			initialized: state => state.isInitialized,
			openedSubstructureParentDepartmentId: state => state.openedSubstructureParentDepartmentId,
			structure: state => state.items
		}),
		items() {
			return this.structure.filter(x => x.level <= 3);
		},
		dialogItems() {
			const parentItem = this.structure.find(x => x.id === this.openedSubstructureParentDepartmentId);
			const departments = flatMapDeep(parentItem.departments, (item) => [item, ...(item.departments || [])]);
			const structureDepartments = departments.map(x => this.structure.find(y => x.id === y.id));
			
			return [parentItem, ...structureDepartments];
		},
		openedEmployee() {
			if(!this.openedEmployeeMenuId)
				return;

			let result;

			for (const item of this.structure) {
				if(item.head?.id === this.openedEmployeeMenuId) {
					result = item.head;
					break;
				}

				const employee = (item.employees ?? []).find(x => x.id === this.openedEmployeeMenuId);

				if(employee) {
					result = employee;
					break;
				}
			}

			return result;
		},
		openedDepartmentEmployees() {
			if(!this.openedEmployeesMenuDepartmentId)
				return;

			const item = this.structure.find(x => x.id === this.openedEmployeesMenuDepartmentId);

			return [item.head, ...item.employees];
		}
	},
	methods: {
		...mapMutations({
			setOpenedSubstructureParentDepartmentId: mutationTypes.SET_OPENED_SUBSTRUCTURE_PARENT_DEPARTMENT_ID
		}),
		handleEmployeesMenu(value, positionX, positionY, departmentId) {
			if(!value) {
				this.openedEmployeesMenuDepartmentId = "";
				this.isEmployeesMenuOpened = false;
				return;
			}
			
			this.openedEmployeesMenuDepartmentId = departmentId;
			this.employeesMenuPositionX = positionX;
			this.employeesMenuPositionY = positionY;
			this.isEmployeesMenuOpened = true;
		},
		handleEmployeeMenu(value, positionX, positionY, userId) {
			if(!value) {
				this.openedEmployeeMenuId = "";
				this.isEmployeeMenuOpened = false;
				return;
			}
			
			this.openedEmployeeMenuId = userId;
			this.employeeMenuPositionX = positionX;
			this.employeeMenuPositionY = positionY;
			this.isEmployeeMenuOpened = true;
		},
		async handleSubstructureDialog(value, parentDepartmentId) {
			if(!value) {
				this.isSubstructureDialogOpened = false;
				return;
			}
			
			this.setOpenedSubstructureParentDepartmentId(parentDepartmentId);
			this.substructureLevelsCount = uniq(this.dialogItems.map(x => x.level)).length;
			this.substructureLevelMaxItemsCount = max(Object.values(groupBy(this.dialogItems, "level")).map(x => x.length));
			this.isSubstructureDialogOpened = true;
		},
	},
	async created() {
		await this.initializeStore();
	},
	components: {
		HrSubsrtuctureDialog,
		FrpDialog,
		FrpBtn,
		HrOrganizationStructureDiagram,
		FrpRouterLinkBtn,
		FrpSpinnerLoader,
		FrpAvatar,
		KpiContentLayout
	}
};
</script>
