import IPageState from "@/store/shared/base/types/pageState";
import { ApiHrKpiReport } from "@/api/kpi/types/apiHrKpiReport";
import { ApiHrPeriod } from "@/api/hr/types/apiHrPeriod";

export default class EmployeeKpiState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isLoading: boolean = false,
		public kpi: ApiHrKpiReport = {} as ApiHrKpiReport,
		public period: ApiHrPeriod = {} as ApiHrPeriod,
	)
	{
	}
}
