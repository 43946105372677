import VacationPlansFilter from "@/store/hr/modules/vacationPlans/types/vacationPlansFilter";
import { ApiHrEmployee } from "@/api/hr/types/apiHrEmployee";
import { ApiHrVacationYear } from "@/api/hr/types/vacation/apiHrVacationYear";
import { getHrDefaultYear } from "@/views/hr/helpers/getHrDefaultYear";
import { HrVacationPlansItem } from "@/types/hr/vacation/hrVacationPlansItem";
import { getLastActualVacationPlan } from "@/store/hr/modules/vacationPlans/helpers/getLastActualVacationPlan";

export const getVacationPlansDefaultFilter = (years: ApiHrVacationYear[],
	userItem: HrVacationPlansItem,
	currentUser?: ApiHrEmployee): VacationPlansFilter => {
	const lastPlan = getLastActualVacationPlan(userItem?.vacationsPlans);
	
	const year = getHrDefaultYear(years)?.value;
	const departmentIds = currentUser?.department ? [currentUser.department.id] : [];
	const employeeIds = currentUser?.id ? [currentUser.id] : [];
	const planId = !currentUser?.id || !lastPlan ? null : lastPlan!.id;

	return new VacationPlansFilter(year, departmentIds, employeeIds, planId);
};
