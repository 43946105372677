<template>
	<v-card class="kpi-card pt-4 fill-height d-flex flex-column" :class="{ 'px-5 pb-4': !dense }" elevation="0">
		<div class="d-flex justify-space-between" :class="{ 'px-5 align-center': dense }">
			<div class="d-flex flex-column primary--text text--darken-1">
				<span class="text-body-1 font-weight-bold">{{ title }}</span>
				<span v-if="subtitle" class="text-caption" style="font-weight: 600 !important">{{ subtitle }}</span>
			</div>
			<slot name="title-append">
				<a v-if="href" style="height: fit-content" target="_blank" :href="href">
					<frp-icon :color="colors.primary.darken1" src="ico_open-in-new"></frp-icon>
				</a>
			</slot>
		</div>
		
		<div v-if="$slots.default" class="d-flex align-center" :class="[{
			'justify-space-between': !centeredContent,
			'mt-5': !centeredContent && title && !noMarginTop,
			'flex-grow-1 justify-center': centeredContent
		}, contentClass]">
			<slot></slot>
		</div>
		
		<template v-if="$slots.append">
			<v-spacer v-if="!noSpacer"></v-spacer>
			
			<slot name="append"></slot>
		</template>
	</v-card>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpCustomTitleLoader from "@/components/loaders/common/FrpCustomTitleLoader.vue";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	components: { FrpIcon, FrpCustomTitleLoader, FrpBtn },
	props: {
		href: String,
		title: String,
		subtitle: String,
		dense: Boolean,
		centeredContent: Boolean,
		noMarginTop: Boolean,
		contentClass: String,
		noSpacer: Boolean | undefined
	}
};
</script>

<style scoped>

</style>
