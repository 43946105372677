<template>
	<div class="frp-gantt-month">
		<div class="d-flex justify-center align-center py-2">
			<div class="d-flex justify-center align-center" :style="setVisibilityForDecemberJanuary('prev')">
				<div class="frp-gantt__text text-right">{{ monthText.prev }}</div>
				<frp-btn icon
						 small
						 height="32"
						 :disabled="diagramLoading || !!renderingItemIds.length"
						 class="ml-2"
						 :color="colors.grey.lighten3"
						 @click="goToPrevPeriod">
					<frp-icon src="ico_menu-left" :color="colors.grey.darken2"></frp-icon>
				</frp-btn>
			</div>
			<div class="frp-gantt__text frp-gantt__text--active text-center">{{ monthText.current }}</div>
			<div class="d-flex justify-center align-center" :style="setVisibilityForDecemberJanuary('next')">
				<frp-btn icon
						 small
						 height="32"
						 :disabled="diagramLoading || !!renderingItemIds.length"
						 class="mr-2"
						 :color="colors.grey.lighten3"
						 @click="goToNextPeriod">
					<frp-icon src="ico_menu-right" :color="colors.grey.darken2"></frp-icon>
				</frp-btn>
				<div class="frp-gantt__text text-left">{{ monthText.next }}</div>
			</div>
		</div>
		<div v-if="diagramLoading" class="d-flex justify-center py-10">
			<v-progress-circular size="40" width="3" indeterminate></v-progress-circular>
		</div>
		
		<v-data-table v-else
					  class="frp-gantt"
					  ref="table"
					  item-key="id"
					  :headers="headers"
					  :items="items"
					  disable-pagination
					  hide-default-footer>
			<template v-slot:item="{ item, attrs }">
				<tr v-intersect="(event) => handleIntersect(item, event)">
					<td v-for="column in headers" class="frp-gantt__cell v-data-table__divider" :class="column.cellClass">
						<div v-if="column.value === 'fullName'"
							 class="d-flex align-center pointer"
							 @click="$emit('click:name', item.id)">
							<v-lazy :options="{ threshold: 0.5 }">
								<frp-avatar v-if="avatar"
											:id="item.employee?.pictureFileId"
											size="24" class="mr-2">
								</frp-avatar>
							</v-lazy>
							<span>{{ item.fullName }}</span>
						</div>
						<span v-else>{{ item[column.value] }}</span>
					</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { ApiCalendarDayTypeEnum } from "@/api/calendar/types/ApiCalendarDayTypeEnum";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpAvatar from "@/components/common/FrpAvatar.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import colorsMixin from "@/mixins/colorsMixin";
import BatchService from "@/services/batchService";
import { formatDate } from "@/utils/dates";
import KpiKpisArrayItem from "@/views/kpi/kpis/KpiKpisArrayItem.vue";
import {
	addDays,
	eachDayOfInterval,
	format, getDaysInMonth, getYear, isSameDay,
	isWeekend,
	lastDayOfMonth,
	subMonths
} from "date-fns";
import { capitalize, isArray, sortBy } from "lodash";

const batchService = new BatchService({ interval: 100 });

const HALF_MONTH_START_DAY = 15;
const TASK_OFFSET_X = 4;
const TASK_OFFSET_TOP = 5;
const TASK_HEIGHT = 23;
const TASK_GAP = 4;
const ROW_HEIGHT = 34;

export default {
	mixins: [colorsMixin],
	components: { KpiKpisArrayItem, FrpAvatar, FrpIcon, FrpBtn },
	props: {
		items: Array,
		year: Number,
		month: Number,
		avatar: Boolean,
		noText: Boolean,
		tasksClickable: Boolean,
		loading: Boolean,
		calendarDates: {
			type: Array,
			default: () => []
		},
		maxYear: Number,
		minYear: Number,
	},
	data() {
		return {
			ganttHandlers: [],
			internalMonth: this.month ?? new Date().getMonth(),
			internalYear: this.year ?? new Date().getFullYear(),
			handlers: [],
			renderedItems: [],
			tasks: [],
			taskInitializing: false,
			initialized: false,
			renderingItemIds: [],
		};
	},
	computed: {
		diagramLoading() {
			return this.taskInitializing || !this.initialized || this.loading;
		},
		holidays() {
			return this.calendarDates.filter(x => x.type === ApiCalendarDayTypeEnum.Holiday);
		},
		monthText() {
			const start = this.getMonthName(this.currentPeriodStartDate);
			const startPrev = this.getMonthName(subMonths(this.currentPeriodStartDate, 1));
			const end = this.getMonthName(addDays(this.currentPeriodEndDate, 1));

			return {
				prev: this.internalMonth % 1 === 0 ? `${startPrev} \\ ${start}` : start,
				current: this.internalMonth % 1 === 0 ? start : `${start} \\ ${end}`,
				next: this.internalMonth % 1 === 0 ? `${start} \\ ${end}` : end
			};
		},
		headers() {
			const dates = eachDayOfInterval({
				start: this.currentPeriodStartDate,
				end: this.currentPeriodEndDate
			});
			
			const headers = [];
			
			headers.push({
				cellClass: ["frp-gantt__grid-row"],
				width: "300px",
				divider: true,
				text: "",
				value: "fullName",
				sortable: false
			});
			
			dates.forEach((date) => {
				const isHoliday = this.holidays.some(x => isSameDay(x.date, date));
				
				const weekendClass = isWeekend(date) || isHoliday ? "weekend" : "";
				
				headers.push({
					cellClass: ["frp-gantt__cell", `${format(date, "MMM-dd-yyyy")}`, weekendClass],
					class: ["frp-gantt__scale-cell", weekendClass],
					width: "47px",
					align: "center",
					divider: true,
					text: date.getDate(),
					value: format(date, "MMM-dd-yyyy"),
					sortable: false
				});
			});
			
			return headers;
		},
		currentPeriodStartDate() {
			return this.getPeriodStartDate(this.internalMonth);
		},
		currentPeriodEndDate() {
			return this.getPeriodEndDate(this.internalMonth);
		},
		days() {
			return eachDayOfInterval({ start: this.currentPeriodStartDate, end: this.currentPeriodEndDate });
		}
	},
	methods: {
		handleTaskClick({ period }) {
			this.$emit("click:task", period);
		},
		async initializeTasks() {
			if(this.taskInitializing)
				return;
			
			this.taskInitializing = true;
			let tasks = {};
			
			for(let month = 0; month <= 11.5; month += 0.5) {
				const periodStartDate = this.getPeriodStartDate(month);
				const periodEndDate = this.getPeriodEndDate(month);
				const periodDays = eachDayOfInterval({ start: periodStartDate, end: periodEndDate });
				
				this.items.forEach(({ id, periods }, i) => {
					for (const period of periods) {
						const { startDate, endDate, isApproved, color, text, handler } = period;
						const start = new Date(startDate);
						const end = new Date(endDate);
						
						if(start.getFullYear() !== this.internalYear && end.getFullYear() !== this.internalYear)
							continue;
						
						if(startDate > periodEndDate.getTime() || endDate < periodStartDate.getTime())
							continue;
						
						const isCurrentPeriodIncludesStartDay = isSameDay(startDate, periodStartDate) || startDate > periodStartDate.getTime();
						const isCurrentPeriodIncludesEndDay = isSameDay(endDate, periodEndDate) || endDate < periodEndDate.getTime();
						const startIdx = isCurrentPeriodIncludesStartDay ? periodDays.findIndex(x => isSameDay(start, x)) : 0;
						const endIdx = isCurrentPeriodIncludesEndDay ? periodDays.findIndex(x => isSameDay(end, x)) : periodDays.length - 1;
						
						const task = {
							period,
							itemId: id,
							employeeIdx: i,
							isApproved,
							startDate: format(start, "MMM-dd-yyyy"),
							endDate: format(end, "MMM-dd-yyyy"),
							start,
							end,
							startIdx,
							endIdx,
							color,
							duration: endIdx - startIdx,
							position: 0,
							text,
							handler
						};
						
						if(isArray(tasks[month]))
							tasks[month].push(task);
						else
							tasks[month] = [task];
					}
				});
			}
			
			this.tasks = tasks;
			this.taskInitializing = false;
			
			if(this.initialized)
				await this.rerenderTasks();
		},
		getPeriodStartDate(month) {
			if(month % 1 === 0) {
				return new Date(this.internalYear, month);
			} else {
				const firstMonth = new Date(this.internalYear, Math.floor(month));
				const start = HALF_MONTH_START_DAY - 1;
				
				return addDays(firstMonth, start);
			}
		},
		getPeriodEndDate(month) {
			if(month % 1 === 0) {
				return lastDayOfMonth(new Date(this.internalYear, month));
			} else {
				const secondMonth = new Date(this.internalYear, Math.ceil(month));
				const end = Math.ceil(getDaysInMonth(secondMonth) / 2) - 1;
				
				return addDays(secondMonth, end);
			}
		},
		setVisibilityForDecemberJanuary(type) {
			if(this.minYear && this.internalYear === this.minYear && type === "prev")
				return { visibility: this.monthText[type] === "Декабрь \\ Январь" ? "hidden" : "visible" };
			if(this.maxYear && this.internalYear === this.maxYear && type === "next")
				return { visibility: this.monthText[type] === "Декабрь \\ Январь" ? "hidden" : "visible" };
			
			if(!this.year)
				return {};
			
			return { visibility: this.monthText[type] === "Декабрь \\ Январь" ? "hidden" : "visible" };
		},
		async goToPrevPeriod() {
			if(this.year && this.internalMonth === 0) return;
			
			if(this.internalMonth === 0) {
				this.internalMonth = 11.5;
				this.internalYear--;
			} else {
				this.internalMonth -= 0.5;
				await this.rerenderTasks();
			}
		},
		async goToNextPeriod() {
			if(this.year && this.internalMonth === 11) return;
			
			if(this.internalMonth === 11.5) {
				this.internalMonth = 0;
				this.internalYear++;
			} else {
				this.internalMonth += 0.5;
				await this.rerenderTasks();
			}
		},
		getMonthName(date) {
			return capitalize(formatDate(date, "LLLL"));
		},
		handleIntersect(item, [event]) {
			if(event.isIntersecting && !this.renderedItems.some(x => x.id === item.id))
				this.renderItemTasks(item, event.target.closest("tr"));
			if(!event.isIntersecting && this.renderedItems.some(x => x.id === item.id))
				this.removeItemTasks(item, event.target.closest("tr"));
		},
		async renderItemTasks(item, row) {
			this.renderingItemIds.push(item.id);
			
			if(!row) {
				if(!this.$refs.table) {
					this.renderingItemIds = this.renderingItemIds.filter(x => x.id === item.id);
					return;
				}
				
				const rows = this.$refs.table.$el.querySelector("tbody").children;
				row = Array.from(rows).find(row => item.fullName === row.querySelector("span").textContent.trim());
			}
			
			const cols = Array.prototype.slice.call(row.children, 1);
			
			const tasks = (this.tasks[this.internalMonth] || []).filter(y => item.id === y.itemId);
			const groupedTasks = sortBy(tasks, [(x) => !x.isApproved, (x) => x.start.getDate()]);
			
			cols.forEach((firstCol, i) => {
				let taskOffset = 0;
				
				groupedTasks.forEach((task, j) => {
					if(task.startIdx === i) {
						groupedTasks.forEach(x => {
							if(x.startIdx < i && x.position === taskOffset && (task.startIdx - x.startIdx) <= x.duration)
								taskOffset++;
						});
						
						groupedTasks[j] = { ...groupedTasks[j], position: taskOffset };

						taskOffset++;
					}
				});
			});
			
			groupedTasks.forEach(task => {
				const taskCols = cols.filter((_, i) => i >= task.startIdx && i <= task.endIdx);
				const firstCol = taskCols[0];
				const offsetTop = task.position * (TASK_HEIGHT + TASK_GAP) + TASK_OFFSET_TOP + "px";
				const width = taskCols.reduce((acc, v) => acc + v.getBoundingClientRect().width, 0) - TASK_OFFSET_X * 2 - 1;

				const taskEl = document.createElement("div");
				
				firstCol.style = `height: ${ROW_HEIGHT + task.position * (TASK_HEIGHT + TASK_GAP)}px !important`;
				taskEl.style.position = "absolute";
				taskEl.style.width = `${width}px`;
				taskEl.style.top = offsetTop;
				taskEl.style.left = `${TASK_OFFSET_X}px`;
				taskEl.style.background = task.color;
				taskEl.classList.add("frp-gantt__task");
				taskEl.textContent = this.noText ? "" : task.text;
				taskEl.title = this.noText ? "" : task.text;

				if(this.tasksClickable) {
					const handler = () => this.handleTaskClick(task);
					taskEl.addEventListener("click", handler);
					taskEl.style.cursor = "pointer";
					this.handlers.push({ task, handler });
				}
				
				firstCol.appendChild(taskEl);
			});
			
			if(!this.renderedItems.some(x => x.id === item.id))
				this.renderedItems.push(item);
			
			this.renderingItemIds = this.renderingItemIds.filter(x => x.id === item.id);
		},
		async removeItemTasks(item, row) {
			const tasks = row.querySelectorAll(".frp-gantt__task");
			
			tasks.forEach(x => x.remove());
			
			item.periods.forEach(task => {
				if(this.tasksClickable) {
					this.handlers.filter(x => task.id === x.task.id).forEach(x => {
						removeEventListener("click", x.handler);
					});

					this.handlers = this.handlers.filter(y => task.id === y.task.id);
				}
			});
			
			this.renderedItems = this.renderedItems.filter(x => x.id !== item.id);
		},
		async removeTasks() {
			if(!this.$refs.table)
				return;
			
			const tasks = this.$refs.table.$el.querySelectorAll(".frp-gantt__task");
			tasks.forEach(x => x.remove());
			
			this.handlers.forEach((handler) => removeEventListener("click", handler));
			this.handlers = [];
		},
		async rerenderTasks() {
			await this.removeTasks();
			
			this.renderedItems.filter(x => this.items.some(y => y.id === x.id)).forEach(task => {
				this.renderItemTasks(task);
			});
			
			this.items.forEach(x => {
				if(!this.renderedItems.some(y => y.id === x.id))
					this.renderItemTasks(x);
			});
		}
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.rerenderTasks);
	},
	watch: {
		items: {
			async handler(value) {
				await this.initializeTasks();
				
				if(!this.initialized) {
					window.addEventListener("resize", this.rerenderTasks);
					
					this.initialized = true;
				}
			},
			immediate: true
		},
		async year() {
			await this.initializeTasks();
		},
		async month(value) {
			this.internalMonth = value;
			await this.initializeTasks();
		},
		async internalYear(value) {
			this.$emit("update:year", value);
		},
		async diagramLoading(value) {
			if(value)
				await this.removeTasks();
			else {
				await this.$nextTick();
			}
		}
	}
};
</script>

<style lang="scss">
.frp-gantt-month {
  table {
	border-top: 0;
  }

  .v-data-table__wrapper {
	max-height: calc(100vh - 404px);
	overflow-y: auto;
	border-top: 1px solid var(--v-grey-lighten4);
  }
  
  .v-data-table-header,
  .v-data-table-header th {
	position: sticky;
	top: 0;
	z-index: 5;
	background-color: var(--v-white-base);
  }

  .weekend {
	background-color: var(--v-grey-lighten1);
  }

  .frp-gantt__header {
	min-width: 500px;
	height: 45px;
	gap: 5px;
  }

  .frp-gantt__text {
	font-size: 14px;
	color: var(--v-grey-base);
	min-width: 140px;

	&--active {
	  font-weight: 600;
	  color: var(--v-primary-base);
	}
  }

  .frp-gantt__cell {
	height: 34px !important;
  }
}
</style>
