<template>
	<v-data-table class="frp-gantt frp-gantt-year"
				  ref="table"
				  :headers="headers"
				  :items="items"
				  disable-pagination
				  hide-default-footer>
		<template #item.fullName="{ item }">
			<span @click="$emit('click', item.id)" class="pointer">
				{{ item.fullName }}
			</span>
		</template>
	</v-data-table>
</template>

<script>
import BatchService from "@/services/batchService";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { format } from "date-fns";
import { sortBy } from "lodash";

const batchService = new BatchService({ interval: 100 });

const TASK_OFFSET_X = 4;
const TASK_OFFSET_TOP = 14;
const TASK_HEIGHT = 23;
const TASK_GAP = 8;
const ROW_HEIGHT = 52;

export default {
	props: {
		items: Array,
		year: Number
	},
	data() {
		return {
			ganttHandlers: []
		};
	},
	computed: {
		startDate() {
			return new Date(this.year, 0, 1);
		},
		endDate() {
			return new Date(this.year, 11, 1);
		},
		headers() {
			const months = this.getMonthsForYear();
			const headers = [];
			
			headers.push({
				cellClass: ["frp-gantt__grid-row"],
				width: "22%",
				divider: true,
				text: "",
				value: "fullName",
				sortable: false
			});
			
			months.forEach(({ month, date }) => {
				headers.push({
					cellClass: ["frp-gantt__cell"],
					class: ["frp-gantt__scale-cell"],
					width: "6.5%",
					align: "center",
					divider: true,
					text: month,
					value: format(date, "MMM"),
					sortable: false
				});
			});
			
			return headers;
		},
		tasks() {
			let tasks = [];
			
			this.items.forEach(({ id, periods }, i) => {
				const sortedPeriods = sortBy(periods, ["startDate", (x) => !x.isApproved, "days"]);
				
				sortedPeriods.forEach(({ startDate, endDate, isApproved, color }) => {
					const start = new Date(startDate);
					const end = new Date(endDate);
					
					if(start.getFullYear() !== this.year && end.getFullYear() !== this.year)
						return;
					
					const startIdx = start.getFullYear() === this.year ? start.getMonth() : 0;
					const endIdx = end.getFullYear() === this.year ? end.getMonth() : 11;
					
					tasks.push({
						employeeIdx: i,
						isApproved,
						start,
						end,
						color,
						startIdx,
						endIdx,
						duration: startIdx - endIdx,
						position: 0,
						text: `${format(start, dateFormat)}-${format(end, dateFormat)}`
					});
				});
			});
			
			return tasks;
		}
	},
	methods: {
		getMonthsForYear(year) {
			const months = [];
			
			for (let i = 0; i < 12; i++) {
				const date = new Date(`${year}-${i + 1}-01`);
				const month = formatDate(date, "LLLL");
				
				months.push({
					date,
					month
				});
			}
			
			return months;
		},
		renderTasks() {
			const rows = this.$refs.table.$el.querySelector("tbody").children;
			
			this.items.forEach((x, i) => {
				const row = rows[i];
				const cols = Array.prototype.slice.call(row.children, 1);
				let tasks = this.tasks.filter(y => i === y.employeeIdx);
				
				cols.forEach((firstCol, i) => {
					let taskOffset = 0;
					
					tasks.forEach((task, j) => {
						if(task.startIdx === i) {
							tasks.forEach(x => {
								if(x.startIdx < i && x.position === taskOffset && (task.startIdx - x.startIdx) <= x.duration)
									taskOffset++;
							});
							
							tasks[j] = { ...tasks[j], position: taskOffset };
							
							taskOffset++;
						}
					})
				});
				
				tasks.forEach(task => {
					const taskCols = cols.filter((_, i) => i >= task.startIdx && i <= task.endIdx);
					const firstCol = taskCols[0];
					const offsetTop = task.position * (TASK_HEIGHT + TASK_GAP) + TASK_OFFSET_TOP + "px";
					const width = taskCols.reduce((acc, v) => acc + v.getBoundingClientRect().width, 0) - TASK_OFFSET_X * 2 - 1;
					
					const taskEl = document.createElement("div");
					
					firstCol.style = `height: ${ROW_HEIGHT + task.position * (TASK_HEIGHT + TASK_GAP)}px !important`;
					taskEl.style.position = "absolute";
					taskEl.style.width = `${width}px`;
					taskEl.style.top = offsetTop;
					taskEl.style.left = `${TASK_OFFSET_X}px`;
					taskEl.style.background = task.color;
					taskEl.classList.add("frp-gantt__task");
					taskEl.textContent = task.text;
					
					firstCol.appendChild(taskEl);
				});
			});
		},
		rerenderTasks() {
			batchService.push(() => {
				const tasks = this.$refs.table.$el.querySelectorAll(".frp-gantt__task");
				tasks.forEach(x => x.remove());
				
				this.renderTasks();
			});
		}
	},
	mounted() {
		this.renderTasks();
		
		window.addEventListener("resize", this.rerenderTasks);
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.rerenderTasks);
	},
	watch: {
		items() {
			this.rerenderTasks();
		},
		year() {
			this.rerenderTasks();
		}
	}
};
</script>

<style lang="scss">

</style>
