<template>
	<frp-card :title="$t('titles.bankAccounts')" class="pb-1" v-if="isInitialized">
		<template #content>
			<v-form ref="bankAccountApplicationAccountsForm"
					v-model="bankAccountApplicationAccountsFormValid">
				<v-row>
					<v-col>
						<v-data-table :headers="headers"
									  hide-default-footer
									  item-key="id"
									  :items="editableItem.accounts"
									  :items-per-page="-1"
									  :mobile-breakpoint="880"
									  :item-class="() => 'text-body-2 cy-table-row'"
									  class="bar-accounts-table d-flex flex-column">
							<template #item="{ item }">
								<bar-bank-account-application-information-accounts-table-row-editable :account="item"/>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-form>
		</template>
		
		<template #actions>
			<div class="d-flex justify-end align-center flex-row ml-auto text-break">
				<span class="text-caption font-weight-regular d-flex secondary--text text--lighten-2 mr-2">
					{{ $t("common.transitAccountsWarning") }}
				</span>
				<frp-btn class="text-none pl-1 pr-2"
						 dense
						 outlined
						 :disabled="isFetchBankAccountApplicationLoading || isBankAccountApplicationSaving || isBankAccountApplicationAccountsFormDisabled"
						 @click="addEditableItemAccountsItem"
						 :color="colors.blue.base">
					<v-icon :color="colors.blue.base" class="mr-2">mdi-plus</v-icon>
					{{ $t("buttons.addBankAccount") }}
				</frp-btn>
			</div>
		</template>
	</frp-card>
	<bar-bank-account-application-information-accounts-editable-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import { masks } from "@/constants/masks";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import { listMixin } from "@/mixins/listMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import { actionTypes, mutationTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import { prepareBankAccountRule, requiredRule } from "@/utils/validation";
import BarBankAccountApplicationInformationAccountsEditableLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/accounts/BarBankAccountApplicationInformationAccountsEditableLoader.vue";
import BarBankAccountApplicationInformationAccountsTableRowEditable
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/accounts/BarBankAccountApplicationInformationAccountsTableRowEditable.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, listMixin, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			BankAccountApplicationStatusTypeEnum,
			masks,
			validation: {
				bankAccountNumber: [requiredRule(), prepareBankAccountRule()]
			}
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			bankAccountTypes: state => state.bankAccountTypes,
			currencies: state => state.currencies,
			editableItem: state => state.editableItem,
			isFetchBankAccountApplicationLoading: state => state.isFetchBankAccountApplicationLoading,
			isBankAccountApplicationSaving: state => state.isBankAccountApplicationSaving,
			isBankAccountApplicationAccountsFormDisabled: state => state.isBankAccountApplicationAccountsFormDisabled
		}),
		headers() {
			return [
				{
					text: this.$t("tables.accountType"),
					value: "accountType",
					class: "text-caption primary--text text--lighten-1",
					width: "25%",
					sortable: false
				},
				{
					text: this.$t("tables.accountNumber"),
					value: "accountNumber",
					class: "text-caption primary--text text--lighten-1",
					width: "25%",
					sortable: false
				},
				{
					text: this.$t("tables.accountCurrency"),
					value: "accountCurrency",
					class: "text-caption primary--text text--lighten-1",
					width: "18%",
					sortable: false
				},
				{
					text: this.$t("tables.openingDate"),
					value: "openingDate",
					class: "text-caption primary--text text--lighten-1",
					width: "18%",
					sortable: false
				},
				{
					text: "",
					value: "delete",
					class: "text-caption primary--text text--lighten-1",
					width: "1%",
					sortable: false
				}
			];
		},
		bankAccountApplicationAccountsFormValid: {
			get() {
				return this.isBankAccountApplicationAccountsFormValid;
			},
			set(value) {
				this.setIsBankAccountApplicationAccountsFormValid(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setIsBankAccountApplicationAccountsFormValid: mutationTypes.SET_IS_BANK_ACCOUNT_APPLICATION_ACCOUNTS_FORM_VALID,
			addEditableItemAccountsItem: mutationTypes.ADD_EDITABLE_ITEM_ACCOUNTS_ITEM
		}),
		...mapActions({})
	},
	components: {
		BarBankAccountApplicationInformationAccountsEditableLoader,
		BarBankAccountApplicationInformationAccountsTableRowEditable,
		FrpIcon,
		FrpDateField,
		FrpTextField,
		FrpAutocomplete,
		FrpBtn,
		BarDetailsItem,
		BarDetailsGroup,
		FrpCard
	}
};
</script>

<style scoped>

</style>
